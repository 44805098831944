<template>
  <div>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div style="margin-bottom: 66px">
        <VideoList/>
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
        >
          <VideoList :list="list"/>
        </van-list>
      </div>
    </van-pull-refresh>

  </div>
</template>

<script>
import VideoList from "@/components/VideoList";
import {FindAllVideo} from "@/api/video";
import {taskListByCate} from "@/api/article";
import {Toast} from "vant";

export default {

  components: {VideoList},

  mounted() {
  },

  data() {
    return {
      isLoading: false,
      list:[],
      loading: false,
      finished: false,
      page: 1,
      total: 1
    }
  },

  methods: {
    onClickRight() {
      this.$router.push('/upload')
    },
    getTaskByCate() {
      taskListByCate({
        "jobDataType":2,
        "page": this.page,
        "limit": "2"
      }).then((res) => {
        if (res.code == 0) {
          this.list = this.list.concat(res.result.records || [])
          this.total = res.result.total
        }
        this.isLoading = false;
        this.loading = false;
        console.log(this.list,'this.list',res.result)
        if (this.list.length >=this.total||(!res.result.records.length)) {
          this.finished = true;
        } else {

        }
      })
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast('刷新成功');
        this.isLoading = false;
        this.count++;
      }, 1000);
      this.getTaskByCate()
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      this.page += 1
      this.getTaskByCate()
    },
  },

}
</script>

<style scoped>

</style>
